<template>
    <section class="my-likes-section">
        <div class="container my-container">
            <div class="my-likes-content">
                <div class="row no-gutters">
                    <div class="col-sm-12">
                    <h4><a href="">Back</a> <span>|</span> My likes</h4>
                    </div>
                    <div class="col-md-3">
                    <form class="d-flex">
                        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                    </form>
                    </div>
                    <div class="col-md-3">
                    </div>
                    <div class="col-md-3">
                    <div class="mb-3 row">
                        <label for="staticEmail" class="col-sm-2 col-form-label">Sort</label>
                        <div class="col-sm-10">
                        <select class="js-example-basic-single" name="zipcode" style="width: 100%;">
                            <option value="AL">All</option>
                            <option value="WY">Hardcover</option>
                            <option value="WY">Paperback</option>
                            <option value="WY">Other Article</option>
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="col-md-3">
                    <div class="mb-3 row">
                        <label for="staticEmail" class="col-sm-2 col-form-label">Filter</label>
                        <div class="col-sm-10">
                        <select class="js-example-basic-single" name="zipcode" style="width: 100%;">
                            <option value="AL">All</option>
                            <option value="RF">Red Flag</option>
                            <option value="AA">Available</option>
                            <option value="IC">In-Circulation</option>
                            <option value="AC">Aquaried</option>
                        </select>
                        </div>
                    </div>
                    </div>
                </div>
                
                <div class="articles-section">
                    <ul>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                    </li>
                    </ul>
                </div>

                <nav aria-label="Page navigation" class="pagination-member">
                    <ul class="pagination">
                    <li class="page-item">
                        <a class="page-link" href="#" aria-label="Previous">
                        <i class="fas fa-chevron-left"></i>
                        </a>
                    </li>
                    <li class="page-item "><a class="page-link active" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                        <a class="page-link" href="#" aria-label="Next">
                        <i class="fas fa-chevron-right"></i>
                        </a>
                    </li>
                    </ul>
                </nav>
            </div>
        </div>
    </section>
</template>